/* Container Styling */
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title Styling */
.payslips-title {
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Search Container Styling */
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

/* Search Bar Styling */
input[type="text"] {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 200px;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 140, 186, 0.5);
  border-color: #008cba;
}

/* Table Styling */
.payslip-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.payslip-table thead {
  background-color: #116c14;
  color: white;
}

.payslip-table th,
.payslip-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 14px;
}

/* Table Row Hover */
.payslip-table tr:hover {
  background-color: #6deb80;
  cursor: pointer;
}

/* Button Styling */
button {
  margin: 5px;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

/* Print Button Styling */
button:first-child {
  background-color: #008cba;
  color: white;
}

button:first-child:hover {
  background-color: #005f8a;
  transform: translateY(-2px);
}

/* Delete Button Styling */
button:last-child {
  background-color: #f44336;
  color: white;
}

button:last-child:hover {
  background-color: #d32f2f;
  transform: translateY(-2px);
}

/* Loading and Error Styling */
.loading,
.error {
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
  color: #555;
  background-color: #fff3cd; /* Light background for visibility */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ffeeba; /* Border to make it distinct */
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .payslip-table th,
  .payslip-table td {
    font-size: 12px;
    padding: 8px;
  }

  input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: stretch; /* Stretch items to full width */
  }

  input[type="text"],
  button {
    width: 100%; /* Make inputs and buttons full width */
    margin-left: 0; /* Reset margin on smaller screens */
  }

  button {
    margin-bottom: 0; /* No margin below button in stacked view */
  }
}
