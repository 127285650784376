/* src/styles/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

nav {
  background: #333;
  color: #fff;
  padding: 10px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

h2 {
  color: #333;
}

input {
  margin: 5px 0;
  padding: 10px;
  width: 200px;
}

button {
  margin-top: 10px;
  padding: 10px;
}

ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 600px) {
  nav ul {
    display: block;
  }

  nav ul li {
    display: block;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
  }
}
@media print {
  input, button, footer {
    display: none;
  }

  #receipt {
    margin: 0;
    padding: 0;
    border: none;
  }
}
