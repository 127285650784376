/* ViewOfferLetters.css */
.container {
    padding: 20px;
    margin-top: 100px;
    
  }
  
  .offer-letters-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .search-container {
    margin-bottom: 20px;
  }
  
  .search-container input {
    margin: 0 5px;
    padding: 8px;
    font-size: 16px;
  }
  
  .offer-letter-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .offer-letter-table th,
  .offer-letter-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .offer-letter-table th {
    background-color: green;
    color: white;
  }
  
  button {
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  

  /* ViewOfferLetters.css */

/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  color: #333;
  margin: 0;
  padding: 20px;
}

/* Container Styles */
.container {
  max-width: 1900px;
  margin: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

/* Title Styles */
.termination-letters-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #2c3e50;
}

/* Search Container Styles */
.search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-container input {
  width: 30%;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-container input:focus {
  border-color: #2980b9;
  outline: none;
}

/* Table Styles */
.termination-letter-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.termination-letter-table th,
.termination-letter-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.termination-letter-table th {
  background-color: green;
  color: white;
  font-size: 16px;
}

.termination-letter-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.termination-letter-table tr:hover {
  background-color: #e1e1e1;
}

/* Button Styles */
button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #2980b9;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #3498db;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Footer Styles */
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
}

/* Signature Styles */
.signature-box img {
  height: 60px;
  width: 100px;
}

.signature-box p {
  margin-top: 10px;
  font-weight: bold;
}
/* Container for the main content */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Title styling */
.experience-letters-title {
  text-align: center;
  color: #2C3E50;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: bold;
}

/* Search container styling */
.search-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

/* Input styling for search fields */
.search-container input {
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s;
}

.search-container input:focus {
  border-color: #2980B9;
  outline: none;
}

/* Table styling */
.experience-letter-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Table header styling */
.experience-letter-table th,
.experience-letter-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 16px;
}

/* Header styling */
.experience-letter-table th {
  background-color: green;
  color: white;
  font-weight: bold;
}

/* Row styling */
.experience-letter-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.experience-letter-table tr:hover {
  background-color: #e7f1ff; /* Light blue for hover effect */
}

/* Button styling */
button {
  background-color: #2980B9;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color: #1c5980;
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Button focus styles for accessibility */
button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(41, 128, 185, 0.5);
}
.container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.letter-list-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.experience-letters-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.experience-letters-table th,
.experience-letters-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 16px;
}

.experience-letters-table th {
  background-color: #007bff;
  color: white;
}

.experience-letters-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.experience-letters-table tbody tr:hover {
  background-color: #eaeaea;
}

.experience-letters-table button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.experience-letters-table button:hover {
  background-color: #218838;
}

.error-message {
  color: #dc3545;
  font-weight: bold;
}

@media print {
  .experience-letters-table {
    border: none;
  }

  .experience-letters-table th,
  .experience-letters-table td {
    border: 1px solid black;
    padding: 8px;
  }

  .experience-letters-table th {
    background-color: #007bff;
    color: white;
  }

  .experience-letters-table tbody tr:nth-child(even) {
    background-color: #ffffff;
  }

  .experience-letters-table tbody tr:hover {
    background-color: transparent;
  }
}
