/* src/EmployeeDetails.css */

.employee-details-container {
    max-width: 900px;
    margin: 30px auto;
    padding: 30px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .employee-details-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  .employee-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
  }
  
  .employee-title::after {
    content: '';
    width: 100px;
    height: 3px;
    background-color: #007bff;
    display: block;
    margin: 10px auto 0;
  }
  
  .employee-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .employee-image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .employee-image img:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .employee-data {
    flex: 1;
    padding-left: 40px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;
    color: #555;
  }
  
  .employee-data p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    transition: color 0.3s ease;
  }
  
  .employee-data p:hover {
    color: #007bff;
  }
  
  .employee-data p strong {
    font-weight: 500;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .employee-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .employee-data {
      padding-left: 0;
      grid-template-columns: 1fr;
    }
  
    .employee-data p {
      text-align: center;
    }
  }
  .print-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .print-button:hover {
    background-color: #0056b3;
  }
  