.container {
    max-width: 100vw; /* Adjusted for a more compact container */
    margin-bottom: 100px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .id-card {
    width: 2.5in; /* Width for vertical layout */
    height: 3.5in; /* Height for vertical layout */
    padding: 5px; /* Padding adjusted for better spacing */
    border: 2px solid #2d8604;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
    background: rgb(192, 232, 180);
  }
  
  .header {
    margin-bottom: 5px;
  }
  
  .header img {
    width: 40px; /* Smaller company logo */
    margin-bottom: 3px;
  }
  
  .header h2 {
    font-size: 12px; /* Smaller font for header */
    margin: 0;
  }
  
  .header p {
    font-size: 6px; /* Smaller font for details */
    margin: 1px 0;
    line-height: 1.1; /* Line height adjustment for better fit */
  }
  
  .content {
    margin-bottom: 5px; /* Reduced bottom margin */
  }
  
  .content img {
    width: 70px; /* Box-shaped employee image */
    height: 70px; /* Ensures a square shape */
    border-radius: 5px; /* Rounded corners for a softer look */
    margin-bottom: 5px; /* Margin for image spacing */
  }
  
  .content p {
    
    font-size: 10px; /* Smaller font for content */
  }
  
  .director-signature img{

    width: 70px; /* Set width for signature */
    height: 50px; /* Reduced height for signature */
    margin-left: 150px;
    margin-top: -30px;
   
  
  
  }
  
  .print-button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 5px 10px; /* Adjusted padding for button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px; /* Adjusted font size */
    margin-top: 10px; /* Reduced margin */
  }
  
  .print-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .director-signatur{
    font-size: 10px;
    margin-right: -110px;
    margin-top: -20px;
    margin-bottom: 50px;
  }