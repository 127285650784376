.container{
    margin-top: 100px;
}

.add-offer-letter-container{

    margin-top: 100px;
}


.add-termination-letter-container{

    margin-top: 100px;

}

.add-id-card-container{

    margin-top: 100px;
}

.payslip-container{
    margin-top: 100px;
}

.view-termination-letters-container{
    margin-top: 100px;
}

.add-payslip-container{
    margin-top: 100px;

}