/* src/Dashboard.css */
.dashboard-container {
  background: linear-gradient(135deg, #042d14, #106711, #011905);
  display: flex;
  transition: all 0.3s ease;
  height: 100vh;
}

.sidebar {
  
  margin-left: -20px;
  width: 200px;
  background: linear-gradient(135deg, #042d14, #106711, #011905);
  color: #ffffff;
  position: relative;
  transition: width 0.3s ease, opacity 0.3s ease;
  overflow-y: auto;
  padding: 10px;
}

.sidebar.closed {
  width: 80px;
  opacity: 0.9;
}

.toggle-button {
  background: orangered !important;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 15px;
  border-radius: 5px;
  z-index: 1;
  width: calc(100% - 30px);
}

.sidebar-links {
  margin-top: 40px;
  background: linear-gradient(135deg, #042d14, #106711, #011905);
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 15px 0;
}

a {
  text-decoration: none;
  color: #ffffff;
  display: block;
  padding: 10px;
  transition: background 0.3s ease;
}

.dashboard-container a:hover {
  background: linear-gradient(135deg, #042d14, #616710, #191501);
}

.active {
  background: linear-gradient(135deg, #042d14, #106711, #011905);
}

.dashboard-content {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
  overflow-y: auto;
}

.hidden {
  display: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    
    left: -250px;
    z-index: 3;
    overflow-y: scroll; /* Enable scrolling on mobile */
    max-height: 100vh; /* Limit height to viewport */
  }

  .sidebar.open {
    left: 0;
  }

  .toggle-button {
    width: 55px;
    margin-left: 333px;
    margin-top: 0;
    position: fixed;
    z-index: 2;
  }
}
