/* src/PVDashboard.css */
.pv-dashboard {
  background-color: #023058;
  display: flex;
  transition: all 0.3s ease;
  height: 100vh;
}

.pv-sidebar {
  margin-left: -20px;
  width: 200px;
  background-color: #023058;
  color: #ffffff;
  position: relative;
  transition: width 0.3s ease, opacity 0.3s ease;
  overflow-y: auto;
  padding: 10px;
}

.pv-sidebar.closed {
  width: 80px;
  opacity: 0.9;
}

.sidebar-toggle {
  background: orangered !important;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 15px;
  border-radius: 5px;
  z-index: 1;
  width: calc(100% - 30px);
}

.sidebar-nav {
  margin-top: 40px;
  background-color: #023058;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 15px 0;
}

a {
  text-decoration: none;
  color: #ffffff;
  display: block;
  padding: 10px;
  transition: background 0.3s ease;
}

.pv-dashboard a:hover {
  background: linear-gradient(135deg, #04142d, #101a67, #010519);
}

.active-link {
  background: linear-gradient(135deg, #04072d, #101a67, #010319);
}

.pv-content {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
  overflow-y: auto;
}

.hidden {
  display: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pv-sidebar {
    position: fixed;
    left: -250px;
    z-index: 3;
    overflow-y: scroll; /* Enable scrolling on mobile */
    max-height: 100vh; /* Limit height to viewport */
  }

  .pv-sidebar.open {
    left: 0;
  }

  .sidebar-toggle {
    width: 50px;
    margin-left: 333px;
    margin-top: 20px;
    position: fixed;
    z-index: 2;
  }
}
