/* EmployeeList.css */

.container {
  max-width: 1200px;
  margin-top: 100px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.list-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.employee-list table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.employee-list th, .employee-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.employee-list th {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

.employee-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

.employee-list tr:hover {
  background-color: #e9e9e9;
}

.print-button, .delete-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 5px;
  color: white;
  transition: background-color 0.3s;
}

.print-button {
  background-color: #2196F3;
}

.print-button:hover {
  background-color: #1976D2;
}

.delete-button {
  background-color: #f44336;
}

.delete-button:hover {
  background-color: #d32f2f;
}

/* Print-specific styling */
@media print {
  body * {
    visibility: hidden;
  }
  
  .print-window, .print-window * {
    visibility: visible;
  }

  .print-window {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
