/* AddEmployee.module.css */

.container {
  max-width: 700px;
  margin: 20px auto;
  margin-top: 100px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.form-title {
  font-size: 2.2em;
  text-align: center;
  color: #023058;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
}

.form-title::after {
  content: "";
  width: 50px;
  height: 4px;
  background-color: #023058;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

.employee-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form-group {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  gap: 8px;
  width: 100%;
}

label {
  font-size: 1.2em; /* Set a consistent font size for labels */
  color: #4a4a4a;
  font-weight: 600;
  text-align: left; /* Left-align labels for consistency */
  margin-bottom: 5px; /* Space between label and input */
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="file"],
textarea,
select {
  padding: 12px 16px;
  font-size: 1em;
  color: #333;
  border: 1px solid #d1d9e6;
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #023058;
  box-shadow: 0 0 8px rgba(2, 48, 88, 0.2);
}

.submit-button {
  padding: 12px 20px;
  font-size: 1.1em;
  background-color: #023058;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 600px;
  width: 100%;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.submit-button:hover {
  background-color: #01447c;
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(1, 68, 124, 0.3);
}

.image-preview {
  margin-top: 10px;
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.error {
  font-size: 0.9em;
  color: #e74c3c;
  margin-top: 5px;
  text-align: center;
  background-color: #fce4e4;
  padding: 6px;
  border-radius: 4px;
}

/* Placeholder styling */
input::placeholder,
textarea::placeholder {
  color: #9aa5b1;
  font-style: italic;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .form-title {
    font-size: 1.8em;
  }

  label {
    font-size: 1em; /* Ensure label font size is consistent */
  }

  input,
  select,
  .submit-button {
    font-size: 0.9em;
  }
}
