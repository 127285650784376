/* Full-screen background and overlay */
.home-page {
    background-image: url('./collage.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #ffffff;
  }
  
  /* Semi-transparent overlay */
  .overlay {
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for better text readability */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Centered content */
  .content {
    text-align: center;
    font-size: larger;
    padding: 20px;
    max-width: 80vw;
  }
  
  /* Logo styling */
  .logo {
    height: 100px;
    margin-bottom: 20px;
    border-radius: 58px;
  }
  
  /* Text styling */
  .foundation-name {
    font-size: 2.5em;
    font-weight: 700;
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .foundation-taglines {
    font-size: 1.9em;
    font-style: italic;
    margin: 10px 0;
  }
  
  .foundation-detailss {
    font-size: 1.5em;
    line-height: 1.5em;
    max-width: 500px;
    margin: 0 auto;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .home-page {
        height: 100vh; /* Adjust height for mobile to prevent overlap */
        width: 100vw;
        margin-left: -30px;
        z-index: 0;  /* Push this element behind others on mobile */
    }
}
  
  
