/* src/BeneficiaryList.css */

.beneficiary-list-container {
  max-width: 1200px; /* Increased max-width for larger screens */
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif; /* Added font for consistency */
}

.beneficiary-list-title {
  text-align: center;
  color: #007BFF;
  margin-bottom: 20px;
  font-size: 2em; /* Larger title for emphasis */
}

.search-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em; /* Added font size for better readability */
}

.print-button {
  display: block;
  margin: 10px auto;
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Transition for hover effect */
}

.print-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.loading {
  text-align: center;
  color: #007BFF;
  font-size: 1.2em; /* Increased loading text size */
}

.error {
  color: red;
  text-align: center;
  font-size: 1em; /* Ensure error text is visible */
}

.beneficiary-table {
  width: 100%; /* Full-width table */
  border-collapse: collapse;
  margin-top: 20px;
}

.beneficiary-table th{
  background-color: green;
  color: white;
}
.beneficiary-table th, .beneficiary-table td {
  border: 1px solid #5a8652; /* Enhanced border color */
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
  font-size: 1em; /* Set font size for table cells */
}

.sortable-header {
  cursor: pointer;
  color: #262003; /* Color for sortable headers */
}

.beneficiary-table tr:nth-child(even) {
  background-color: #f0f8ff; /* Light background for even rows */
}

.beneficiary-table tr:hover {
  background-color: #badfbb; /* Light green on hover */
}

.details-link {
  color: #9b8203; /* Consistent link color */
  text-decoration: underline; /* Underline for better visibility */
}





.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Transition for hover effect */
}

.delete-button:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}

/* Responsive Design */
@media (max-width: 600px) {
  .beneficiary-list-container {
    padding: 10px; /* Reduced padding for smaller screens */
  }
  .beneficiary-list-title {
    font-size: 1.5em; /* Adjust title size */
  }
  .search-input {
    font-size: 0.9em; /* Smaller input font size */
  }
  .beneficiary-table th, .beneficiary-table td {
    font-size: 0.9em; /* Smaller table font size */
  }
}
