/* ViewPayslips.css */

/* Container styling */
.payslip-container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 100px;
}

.payslip-container h1 {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

table th, table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Button styling */
button {
  padding: 6px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  opacity: 0.9;
}

button:active {
  transform: scale(0.98);
}

button:first-of-type {
  background-color: #007BFF;
  color: white;
}

button:last-of-type {
  background-color: #DC3545;
  color: white;
}

/* Print page styling */
@media print {
  .payslip-container, .payslip-container h1, table, button {
    display: none;
  }
}
