/* src/BeneficiaryDetails.css */

.beneficiary-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .beneficiary-title {
    text-align: center;
    color: green;
  }
  
  .beneficiary-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .beneficiary-image {
    flex: 0 0 auto; /* Prevent the image from growing */
    margin-right: 20px;
  }
  
  .beneficiary-image img {
    max-width: 150px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .beneficiary-data {
    flex: 1; /* Allow this section to grow */
  }
  
  .beneficiary-data p {
    margin: 5px 0;
  }
  
  .beneficiary-data p strong {
    color: #555;
  }
  
  .quote {
    font-style: italic;
    margin: 20px 0;
    text-align: center;
    color: #555;
  }
  
  .print-button {
    background-color: #07650b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    text-align: center;
    width: 100%;
  }
  
.receipt{
    color: green;
}