.nirvan-navbar {
    display: flex;
    margin-top: -25px;
    margin-left: -20px;
    width: 100vw;
    height: 13vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #023058;
    padding: 5px 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.nirvan-navbar-logo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.nirvan-logo-img {
    height: 60px;
    width: auto;
    border-radius: 50px;
}

.nirvan-company-name {
    font-size: 1.5em;
    color: wheat;
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
}

.nirvan-admin-tag {
    background: green;
    color: wheat;
    padding: 3px 6px;
    font-size: 1.2em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 3px;
    margin-left: 5px;
}

.nirvan-navbar-quote {
    font-size: 0.9em;
    color: #ffeded;
    font-style: italic;
    text-align: center;
}

.nirvan-navbar-directors {
    display: flex;
    gap: 10px;
}

.nirvan-director {
    text-align: center;
    margin-left: -250px;
}

.nirvan-director-img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.nirvan-director p {
    font-size: 0.7em;
    color: #333;
    margin: 0;
}

@media (max-width: 768px) {
    .nirvan-navbar {
        margin-bottom: 20px;
        height: 10vh;
        position: fixed; /* Keep button fixed */
        z-index: 2; /* Ensure it's above everything */
    }

    .nirvan-director {
        display: none;
    }

    .nirvan-admin-tag {
        background: green;
        color: wheat;
        margin-top: 20px;
        margin-left: -250px;
        font-size: 0.7em;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border-radius: 3px;
    }

    .nirvan-navbar-quote {
        display: none;
    }

    .nirvan-company-name {
        font-size: 0.9em;
        color: #ffffff;
        margin-top: -15px;
        font-family: 'Times New Roman', Times, serif;
        font-weight: 600;
    }

    .nirvan-logo-img {
        height: 50px;
        width: auto;
        margin-top: -1px;
        border-radius: 50px;
    }
}
