/* Container styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Search input styling */
.search-input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #5a67d8;
}

.print-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.print-button:hover {
  background-color: #2cb53a;
}

/* Table styling */
.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff;
}

.employee-table th,
.employee-table td {
  padding: 12px 15px;
  text-align: left;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.employee-table th {
  cursor: pointer;
  color: #16892f;
  font-weight: 600;
  text-transform: uppercase;
}

.employee-table th:hover {
  color: #028d2e;
}

.employee-table tr:hover {
  background-color: #7bdf7b;
}

/* Employee ID and salary columns */
.employee-table td:nth-child(1),
.employee-table td:nth-child(5) {
  font-weight: 500;
  color: #2d3748;
}

/* Details link */
.details-link {
  color: #0c5a06;
  text-decoration: none;
  font-weight: 500;
}

.details-link:hover {
  text-decoration: underline;
}

/* Delete button */
.delete-button {
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: #e53e3e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c53030;
}

/* No employees found message */
.employee-table td[colspan="7"] {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #718096;
}
